<template>
  <div id="HomeView" class="container max-w-4xl mx-auto pt-10 pb-20 px6">
    <div class="text-center">
      <router-link to="/" class="text-blue-500 no-underline hover:underline">
        Scan Out
      </router-link>
    </div>
    <div v-if="notice != null" class="text-green-700 text-center">{{ notice }}</div>
    <div v-if="error != null" class="text-red-700 text-center">{{ error }}</div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <TextInput 
            label="UID Codes (comma separated if more than 1)"
            id="uid_code"
            placeholder="12345,12346,12347"
            v-model:input="uidCode"
            inputType="text"
            @keyup.enter="searchUdiCode()"
        />
      </div>
    </div>

    <div class="flex flex-wrap mt-8 mb-6">
      <div class="w-full px-3">
        <SubmitFormButton 
            btnText="Search"
            @click="searchUdiCode()"
        />
      </div>
    </div>

    <!-- Add the results display section -->
    <div v-if="orders.length > 0" class="mt-8">
      <div class="grid gap-4">
        <div v-for="order in orders" :key="order.order_id" 
             class="bg-white rounded-lg shadow p-4 hover:shadow-lg transition-shadow">
          <div class="grid grid-cols-2 gap-2">
            <div class="text-gray-600">Order Number:</div>
            <div class="font-medium">{{ order.order_id }}</div>
            
            <div class="text-gray-600">Name:</div>
            <div class="font-medium">{{ order.ship_to_name }}</div>
            
            <div class="text-gray-600">Scan Date:</div>
            <div class="font-medium">{{ new Date(order.created_at).toLocaleDateString() }}</div>
            
            <div class="text-gray-600">UID Code:</div>
            <div class="font-medium">{{ order.outbound_order_items[0].uid_code }}</div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  // import Swal from 'sweetalert2'
  import TextInput from '@/components/global/TextInput.vue'
  import SubmitFormButton from '@/components/global/SubmitFormButton.vue'

  let uidCode = ref(null)
  let orders = ref([])
  let notice = ref(null)
  let error = ref(null)

  onMounted(async () => {
      // do nothing
  })

  const searchUdiCode = async () => {
    try {
      let uidCodes = uidCode.value.split(',')
      notice.value = null
      error.value = null
      let res = await axios.post(process.env.VUE_APP_API_URL + 'api/v1/oders_with_uid_codes', {
        uid_codes: uidCodes
      })
      orders.value = res.data
      if (orders.value.length > 0) {
        notice.value = "Search matched at least 1 order scanned with UID code " + uidCode.value
      } else {
        error.value = "No orders were scanned matching UID code " + uidCode.value
      }
      uidCode.value = null
    } catch (err) {
      // nothing
    }
  }
</script>
